import { useState } from "react";
import { Form } from "../../Forms/Form";
import { Field, FieldCheckbox } from "../../Forms/Field";
import { ASelect, Checkbox, Input } from "../../Forms/Input";
import { useStore } from "../../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import { ChevronRightOutlined, CloseOutlined, LeftCircularOutlined, PlusOutlined } from "../../../OldDesign/Icons";
import { REACT_BASE_URL } from "../../../../api/constants";
import ReactDatePicker from "react-datepicker";
import { addYears, subYears } from "date-fns";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

const ExperienceDetailsCard = (props) => {
  const { t } = useTranslation();
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const [selectedExperiences, setSelectedExperiences] = useState(
    coreCV.experiences_array.map((exp) => {
      return {
        ...exp,
        job_id: { job_id: exp.job_id, job_name: exp.job.name },
        start_date: new Date(`${exp.start_date}-01`),
        end_date: exp.end_date ? new Date(`${exp.end_date}-01`) : null,
      };
    }),
  );

  const [addingExperience, setAddingExperience] = useState(false);
  const [stillWorking, setStillWorking] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    resetField,
    clearErrors,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      job_id: "",
      firm_name: "",
      company_id: "",
      start_date: null,
      end_date: null,
    },
    mode: "onSubmit",
  });
  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const closeAddingExperience = () => {
    setAddingExperience(false);
    reset();
    clearErrors();
  };
  const checkAndInsertData = () => {
    const experience = getValues();
    if (experience.start_date && experience.firm_name && experience.job_id && (experience.end_date || stillWorking)) {
      setSelectedExperiences((prev) => [...prev, experience]);
      reset();
      clearErrors();
      setAddingExperience(false);
      setStillWorking(false);
    }
  };

  const sendFormData = async (formData) => {
    const experiences = selectedExperiences.map((exp) => {
      //format start date
      let _startDate = new Date(exp.start_date);
      _startDate.setDate(_startDate.getDate() + 1);
      //format end date
      let _endDate = exp.end_date ? new Date(exp.end_date) : null;
      _endDate && _endDate.setDate(_endDate.getDate() + 1);
      //return experiences array items
      return {
        job_id: exp.job_id.job_id,
        firm_name: exp.firm_name,
        company_id: null,
        start_date: _startDate.toISOString().slice(0, 7),
        end_date: _endDate ? _endDate.toISOString().slice(0, 7) : null,
      };
    });
    // console.log(experiences);
    await updateApplicationForm({ experiences_array: experiences }, props.handleForwardForm);
  };
  return (
    <>
      <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
        {addingExperience ? (
          <>
            <fieldset className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
                  {t("candidate.corecv_form.experiences")}
                </h2>
                <div className="flex flex-row gap-4">
                  <button type="button" onClick={() => closeAddingExperience()}>
                    <ChevronRightOutlined className="inline-block h-4 w-4 rotate-180 text-error-400" />
                    <p className="inline-block text-[13px] text-error-400">{t("button.cancel")}</p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      checkAndInsertData();
                      trigger();
                    }}
                    className="rounded-lg bg-primary-700 px-1"
                  >
                    <p className="inline-block text-[13px] text-neutral-100">{t("button.confirm")}</p>
                    <PlusOutlined className="inline-block h-4 w-4 text-neutral-100" />
                  </button>
                </div>
              </div>

              <Field label={t("placeholder.company_name")} error={errors?.firm_name}>
                <Input
                  {...register("firm_name", { required: "Required", max: 100 })}
                  placeholder={t("placeholder.write")}
                />
              </Field>
              <Field label={t("cv_fields.job_title")} error={errors?.job_id}>
                <Controller
                  control={control}
                  name="job_id"
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ASelect
                      defaultValue={value}
                      selected={value}
                      onBlur={onBlur}
                      loadOptions={loadJobTitleOptions}
                      onChange={onChange}
                      placeholder={t("placeholder.select")}
                      getOptionLabel={(opt) => opt.job_name}
                      getOptionValue={(opt) => opt.job_id}
                      className={"max-h-[210px]"}
                    />
                  )}
                />
              </Field>
              <div className="flex flex-row gap-4">
                <Field label={t("placeholder.start_date")} error={errors?.start_date}>
                  <Controller
                    control={control}
                    name="start_date"
                    rules={{ required: "Required" }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={Date.parse(value)}
                        onChange={(date) => onChange(date)}
                        onBlur={onBlur}
                        selectsStart
                        startDate={value}
                        endDate={watch("start_date")}
                        maxDate={addYears(new Date(), 10)}
                        minDate={subYears(new Date(), 100)}
                        placeholderText={t("placeholder.select")}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                      />
                    )}
                  />
                </Field>
                <Field label={t("placeholder.end_date")} error={errors?.end_date}>
                  <Controller
                    control={control}
                    name="end_date"
                    rules={!stillWorking && { required: "Required" }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={Date.parse(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        selectsEnd
                        startDate={watch("start_date")}
                        endDate={value}
                        maxDate={addYears(new Date(), 10)}
                        minDate={subYears(new Date(), 100)}
                        placeholderText={t("placeholder.select")}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={stillWorking}
                        className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                      />
                    )}
                  />
                </Field>
              </div>
              <FieldCheckbox className={"border-neutral-300"} label={t("placeholder.still_working")}>
                <Checkbox
                  value={stillWorking}
                  onChange={(e) => {
                    setStillWorking(e.target.checked);
                    resetField("end_date");
                  }}
                />
              </FieldCheckbox>
            </fieldset>
          </>
        ) : (
          <>
            <h2 className="mb-2 border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
              {t("candidate.corecv_form.experiences")}
            </h2>
            <div className="flex flex-1 flex-col gap-2 overflow-scroll pb-2">
              <button
                onClick={() => setAddingExperience(true)}
                className="flex w-full justify-center rounded-xl border border-dashed border-primary-500 bg-primary-50 p-3"
              >
                <span className="text-[16px] font-medium leading-tight text-primary-700">{t("button.add")} +</span>
              </button>
              {selectedExperiences.length > 0 &&
                selectedExperiences.map((exp, index) => {
                  const startDate = exp.start_date?.toLocaleString(i18n.language.slice(0, 2), {
                    month: "short",
                    year: "numeric",
                  });
                  const endDate = exp.end_date?.toLocaleString(i18n.language.slice(0, 2), {
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      key={index}
                      className="flex w-full flex-row justify-between gap-4 rounded-xl border border-primary-500 bg-primary-50 p-3"
                    >
                      <div className="text-[15px] font-medium leading-tight text-primary-700">
                        <h4>{exp.firm_name}</h4>
                        <p>{exp.job_id.job_name}</p>
                        <p>
                          {startDate} - {endDate ? endDate : t("common.continuing")}
                        </p>
                      </div>
                      <div className="flex items-start gap-2">
                        <button
                          onClick={(e) => setSelectedExperiences((prev) => prev.filter((e, i) => i !== index))}
                          type="button"
                        >
                          <CloseOutlined className="h-5 w-5 text-primary-700" />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="flex justify-center gap-20">
              <button
                disabled={isSubmitting}
                type="button"
                id="backwardArrow"
                onClick={() => props.handleBackwardForm()}
              >
                <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
              </button>
              <button disabled={isSubmitting} type="submit" id="forwardArrow">
                <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
              </button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default ExperienceDetailsCard;
