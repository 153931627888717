import { Fragment } from "react";
import SectionTitle from "./SectionTitle";
import { useStore } from "../../store/useStore";
import { Wordcloud } from "@visx/wordcloud";
import { ParentSize } from "@visx/responsive";
import Tooltip from "./Tooltips/Tooltip";
import { useTranslation } from "react-i18next";

const PersonalityType = () => {
  const { t } = useTranslation();
  const userName = useStore((state) => state.overview.data.userName);
  const charts = useStore((state) => state.overview.data.metacv);

  function showTooltip(evt, text) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left = evt.clientX > 150 ? evt.clientX - 130 + "px" : evt.clientX - 20 + "px";
    tooltip.style.top = evt.clientY + 10 + "px";
  }

  function hideTooltip() {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  }
  return (
    <section className="lg:container2 flex gap-9 rounded-xl bg-primary-50 p-9 text-[18px] font-medium leading-tight text-primary-1000 max-lg:flex-col max-lg:gap-4 max-lg:bg-neutral-100 max-lg:p-0 max-lg:py-4 max-lg:text-center max-lg:text-[13px] max-lg:font-normal lg:h-[510px]">
      <div className="flex flex-1 flex-col justify-center py-9 max-lg:py-0">
        <SectionTitle
          className="items-start pb-9 max-lg:items-center max-lg:pb-2"
          title={t("modal.metacv.personality_type")}
        />
        <div className="flex flex-col gap-6 pb-12 max-lg:gap-1 max-lg:pb-0">
          <p>{t("candidate.personality_type_welcoming", { username: userName })}</p>
        </div>
      </div>
      <div>
        <div className="mx-auto h-[390px] w-[650px] max-lg:h-[350px] max-lg:w-[350px]">
          <div
            id="tooltip"
            display="none"
            className="fixed z-[999] hidden max-w-[360px] rounded-lg bg-primary-800 p-2 text-sm text-neutral-100 max-lg:text-xs"
          ></div>
          <ParentSize>
            {({ width, height }) => (
              <Wordcloud
                words={charts.word_cloud}
                fontSize={(word) => (width < 360 ? Math.sqrt(word.value) : Math.sqrt(word.value) * 1.4)}
                padding={8}
                font={"Inter"}
                rotate={(word) => 0}
                spiral="archimedean"
                width={width}
                height={height}
                random={() => 0.5}
              >
                {(cloudWords) =>
                  cloudWords.map((word, index) => {
                    return (
                      <Fragment key={index}>
                        <defs key={index}>
                          <filter x="-4%" y="-5%" width="108%" height="110%" id={index}>
                            <feFlood floodColor={word.bgColor} />
                            <feGaussianBlur stdDeviation="2" />
                            <feComponentTransfer>
                              <feFuncA type="table" tableValues="0 0 0 1" />
                            </feComponentTransfer>

                            <feComponentTransfer>
                              <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                            </feComponentTransfer>
                            <feComposite operator="over" in="SourceGraphic" />
                          </filter>
                        </defs>
                        <text
                          style={{
                            fontSize: word.size,
                            fontWeight: Math.log(word.value) * 100,
                            fill: word.color,
                            fontFamily: word.font,
                            cursor: "help",
                          }}
                          filter={`url(#${index})`}
                          textAnchor="middle"
                          transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                          onMouseMove={(e) => showTooltip(e, word.explain.description)}
                          onMouseLeave={hideTooltip}
                        >
                          {word.text}
                        </text>
                      </Fragment>
                    );
                  })
                }
              </Wordcloud>
            )}
          </ParentSize>
        </div>
        <div className="flex flex-wrap justify-center gap-4">
          {charts.upper_dimensions.map((upType, index) => {
            return (
              <Tooltip key={index} content={upType.description}>
                <div className="flex cursor-help items-center justify-center gap-1">
                  <span className={`text-sm`} style={{ color: upType.color }}>
                    {"\u2B24"}
                  </span>
                  <p className="text-sm">{upType.name}</p>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PersonalityType;
